*{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
html,body{
    font-weight: 200;
    margin: 0px;
    padding: 0px;
}

.App{
    padding:0rem 0 !important;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 100ms ease-in-out;
    transition-delay: 50ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ei-logo{
/*	filter: invert(45%) sepia(76%) saturate(971%) hue-rotate(0deg) brightness(107%) contrast(103%);*/
	width: 220px;
}

h1{
    font-weight: 800;
    font-size: 2.87rem;
}
h2{
    font-size: 1.375rem !important;
    font-weight: 800;  
    color: #0b1320; 
}
.semibold-f{
    font-weight: 600 !important;
}
.extraBold{
    font-weight: 800 !important;
}
/*btn css*/
.bt-round{
    border-radius: 4px !important;
}
.exploreBtn{
  display: inline-flex !important;
  align-items: center;  
  font-size: 1rem !important;
}
.exploreBtn i{
    padding-left: 5px;
}
.exploreBtn i{
    width: 25px;
    padding-top: 5px;
}
.exploreBtn i path{
    fill: #ffffff
}


/*generic css*/
.shadow-box{
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
}

.reverse-symbol{
    transform: rotate(-180deg);
}
.quotesImg{
    width: 7%;
    height: auto;
}
.v-seperator{
    padding-top: 20px
}

/*Rotating Text*/
.rotate-wrapper{
    position: relative;
    font-size: 1.875rem !important;
    font-weight: 800;
}
.rotate-wrapper span{
    font-size: 1.875rem !important;
    font-weight: 800;
}
.rotate-wrapper .rw-wrapper{
    display: inline-block;
    height: .9em;
    margin: 0;
    padding: 0;
    width: 240px;
    text-align: left;
    padding-left: 13px;
}
.rotate-wrapper .rotating-word:first-of-type {
    opacity: 1;
}
.rotate-wrapper .rotating-word:nth-of-type(2) {
    animation-delay: 4s;
}
.rotate-wrapper .rotating-word:nth-of-type(3) {
    animation-delay: 8s;
}
.rotate-wrapper .rotating-word:nth-of-type(4) {
    animation-delay: 12s;
}
.rotate-wrapper .rotating-word:nth-of-type(5) {
    animation-delay: 16s;
}
.rotate-wrapper .rotating-word{
    animation: word-rotate 20s linear 0s infinite;
    display: block;
    bottom: 0;
    left: 0;
    align-items: flex-start;
    opacity: 0;
    position: absolute;
    right: 0;
    line-height: 1.5;
    font-size: 1.875rem !important;
    font-weight: 800;
    text-align: center;
    color:#fe414d;
}


@keyframes word-rotate{
    0%{opacity:0}
    1%{opacity:0;transform:translateY(30px)}
    2%{opacity:1;transform:translateY(0)}
    14%{opacity:1;transform:translateY(0)}
    15%{opacity:0;transform:translateY(30px)}
    80%{opacity:0}to{opacity:0}
}

@-webkit-keyframes scrollUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-33.33%);
        -ms-transform: translateY(-33.33%);
        transform: translateY(-33.33%);
    }
}
@keyframes scrollUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-33.33%);
        -ms-transform: translateY(-33.33%);
        transform: translateY(-33.33%);
    }
}

/*clients css begins*/
.wr-clients{
    margin: 0 auto;
    padding: 0;
/*    max-width: 950px;*/
}
.wr-clients li{
    list-style-type: none;
    display: inline-block;
    text-align: center;
/*    height: 60px;*/
    margin: 10px 10px 10px 0;
}
/*.wr-clients li:last-child{
    margin-right: 0;
}*/
/*.wr-clients li img{
    height: 100%;
    margin: 0 auto
}*/

@media screen and (max-width: 1023px){
    .aurum-navbar{
        position: fixed;
        left:0;
        right: 0;
        top: 0;   
        z-index: 1;
        background-color: #ffffff; 
        padding: 0 20px;
        box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
    }    
}
@media screen and (min-width: 576px){
    .rotate-wrapper .rw-wrapper {
        width: 300px;
    }
}
@media screen and (min-width: 639px){
    .rotate-wrapper span, .rotate-wrapper .rotating-word{
        font-size: 1.875rem !important
    }
    h1{
        font-size: 2.25rem;
    }
    h2{
        font-size: 1.875rem !important;
    }
}
@media screen and (min-width: 1024px){
    .rotate-wrapper span, .rotate-wrapper .rotating-word{
        font-size: 2.25rem !important;
        left: auto;
        right: auto;
        display: inline-flex;
        text-align: initial;
    }
    .wr-clients li{
        height: 100%;
        margin: 10px 20px 10px 0;
    }

    /*.wr-clients li:last-child{
        margin-right: 0
    }*/
}

